import React, { createRef } from "react";
import parse from "html-react-parser";
import { Link } from "gatsby";

export const FormSection11 = ({ business, name }) => {
  const current = 12;
  const wrapper = createRef();

  return (
    <section
      ref={wrapper}
      className="form-section form-11 inactive"
      data-formfield={"form-12"}
      data-slide={`.form-${current - 1}`}
      data-next={`.form-${current + 1}`}
      data-prev={`.form-${current - 1}`}
      data-current={`.form-${current}`}
      data-progress={"100"}
      data-has-statements={"false"}>
      <div className="block">
        <div className="centered">
          <div className="large">
            <p>
              Great to chat <span className="insert_name">{name}</span>!
            </p>
          </div>
          <div className="small">
            {business === "Start Up"
              ? parse(
                  '<p className="start_up">We\'ll be in touch within two working days to discuss how we can help you scale your business.</p>'
                )
              : business === "SME"
              ? parse(
                  '<p className="sme">We\'ll be in touch within 1 working day to discuss how we can help take your business to the next level.</p>'
                )
              : business === "Bluechip"
              ? parse(
                  "<p className=\"bluechip\">Thank you for all the information. We'd like to schedule a call with you to discuss your needs further. We'll be in touch within the next 24 hours.</p>"
                )
              : " "}
          </div>
          <div className="input-wrap">
            <Link to="/" className="button button-capsule no_hoverBG active hover">
              Back to Homepage
            </Link>
            <Link
              to="/services/"
              className="button button-capsule no_hoverBG active margin-left hover">
              Back to Services
            </Link>
            <Link
              to="/case-studies/"
              className="button button-capsule no_hoverBG active margin-left hover">
              Back to Case Studies
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
