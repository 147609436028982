import React, {createRef}  from 'react'

export const FormSection3 = ({nextSection, name, setBusiness}) => {
  const current = 4;
  const wrapper = createRef()

  const updateRoute  = (event) => {
    const answer = event.target.parentNode.dataset.answer;
    const formfield = wrapper.current.dataset.formfield;
    const progress = wrapper.current.dataset.progress;
    nextSection(answer, formfield, progress);
    setBusiness(answer);
    //console.log('section 3 complete')
  }
  return (
    <section 
      ref={wrapper} 
      className="form-section form-3 inactive" 
      data-formfield={'form-3'}  
      data-slide={`.form-${current-1}`} 
      data-next={`.form-${current+1}`}  
      data-prev={`.form-${current-1}`}  
      data-current={`.form-${current}`}   
      data-progress={"38"}
      data-has-statements={"true"}
      >
   		 <div className="block">
   		 	<div className="centered">
		   		<div className="large">
			   		<p>Good to meet you <span className="insert_name">{name}</span>.</p>
		   		</div>
		   		<div className="small">
			   		<p>Which of the following best describes your business?</p>
				</div>
        <div className="options-wrap">
          <div className="options">
            <div id="route-1_step-2_option-1" onClick={updateRoute} className="option hover" data-answer="Start Up"><span id="route-1_step-2_option-1--INNER" className="centered_flex">Start up</span></div>
            <div id="route-1_step-2_option-2" onClick={updateRoute} className="option hover" data-answer="SME"><span id="route-1_step-2_option-2--INNER" className="centered_flex">SME</span></div>
            <div id="route-1_step-2_option-3" onClick={updateRoute} className="option hover" data-answer="Bluechip"><span id="route-1_step-2_option-3--INNER" className="centered_flex">Blue chip</span></div>
          </div>
        </div>
		   	
   		 	</div>
   		 </div>
		</section>
  )
}
