import React, {createRef}  from 'react'
import {Link} from 'gatsby';

export const Route2FormSection5 = ({changeRoute}) => {

  const wrapper = createRef()


  const updateRoute  = (event) => {
    const progress = wrapper.current.dataset.progress;
    changeRoute(progress);
  }
  return (
    <section 
    ref={wrapper} 
    className="form-section form-route2 route-2-form-5 inactive" 
    data-progress={"100"}
    data-has-statements={"true"}>
   		 <div className="block">
        <div className="centered">
          <div className="large">
            <p>Thanks!</p>
          </div>
          <div className="small">
           <p className="start_up">I’ll pass this to one of the minions in sector 7G.</p>
          </div>
          <div className="input-wrap">					   	
            <Link to="/"  className="button button-capsule no_hoverBG active hover">Back to Homepage</Link>
            <Link to="/services"  className="button button-capsule no_hoverBG active margin-left hover">Back to Services</Link>
            <Link to="/case-studies" className="button button-capsule no_hoverBG active margin-left hover">Back to Case Studies</Link>
          </div>						
   		 	</div>
   		 </div>
		</section>
  )
}
