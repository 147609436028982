import React, {createRef} from 'react'
import {Link} from 'gatsby'


export const FormSection1 = ({nextSection, changeColour, changeRoute}) => {
  const wrapper = createRef()

  const updateRoute  = (event) => {
    const answer = event.target.parentNode.dataset.answer;
    const col = event.target.parentNode.dataset.col;
    const formfield = wrapper.current.dataset.formfield;
    const progress = wrapper.current.dataset.progress;
    changeColour(col);
    //console.log(answer, formfield,  progress);
    if(answer === 'Potential Lead'){
      nextSection(answer, formfield, progress);
    }else{
      changeRoute()
    }
  }


  return (
    <section 
      ref={wrapper}
      className="form-section route-2-form-1 form-1 active" 
      data-progress={"13"}
      data-formfield={'form-1'}
      data-has-statements={"false"}>

   		 <div className="block">
   		 	<div className="centered">
		   		<h1 className="large">
			   		<p>Hello.</p>
			   		<p>How may I help?</p>
		   		</h1>
		   		<div className="options-wrap ">
			   		<div className="options">
				   		<div id="route-initial_step-1_option-1" className="option hover" onClick={updateRoute} data-col={'col-1'} data-answer="Potential Lead"><span className="centered_flex" id="route-initial_step-1_option-1--INNER">I am interested in your services</span></div>
				   		<div id="route-initial_step-1_option-2" className="option hover" onClick={updateRoute} data-col={'col-2'} data-answer="Sales"><span className="centered_flex" id="route-initial_step-1_option-2--INNER">I have services you might be interested in</span></div>
				   		<Link id="route-initial_step-1_option-3" to="/careers" data-bg-color="bg-white" className="option hover" answer="Work"><span className="centered_flex" id="route-initial_step-1_option-3--INNER">I want to work for you</span></Link>
			   		</div>
		   		</div>
		   </div>
		</div>
		</section>
  )
}
