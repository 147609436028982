import React, { createRef, useEffect, useState } from "react";

export const FormSection5 = ({ nextSection, setWebAddress, currentSlideIndex }) => {
  const current = 6;
  const inputEl = createRef();
  const wrapper = createRef();
  const clickRef = createRef();
  const [hasValue, setHasValue] = useState(false);
  const [hasValueBeenSet, setHasValueBeenSet] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (currentSlideIndex === 5) {
      setHasValueBeenSet(false);
    }
  }, [currentSlideIndex]);

  const checkHasRealURL = (e) => {
    if (e.key === "Enter" && hasValue === true) {
      updateRoute();
      setHasValueBeenSet(false);
      inputEl.current.blur();
      //console.log('section 5 complete')
    }

    if (
      /[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,}[A-Za-z]*(:[0-9]{1,5})?(\/.*)?$/i.test(
        e.target.value
      )
    ) {
      setIsValid(true);

      if (hasValueBeenSet === false) {
        setHasValue(true);
        setHasValueBeenSet(true);
      }

      wrapper.current.querySelector(".submit").classList.add("active");
    } else {
      setIsValid(false);
      setHasValue(false);
      setHasValueBeenSet(false);
      wrapper.current.querySelector(".submit").classList.remove("active");
    }
    //console.log(hasValueBeenSet)
  };

  const updateRoute = (event) => {
    const formfield = wrapper.current.dataset.formfield;
    const progress = wrapper.current.dataset.progress;
    const answer = inputEl.current.value;
    const url = inputEl.current.value;
    //console.log('url', url)
    nextSection(answer, formfield, progress);
    setWebAddress(url);
  };
  return (
    <section
      ref={wrapper}
      className="form-section form-5 inactive"
      data-formfield={"form-5"}
      data-slide={`.form-${current - 1}`}
      data-next={`.form-${current + 1}`}
      data-prev={`.form-${current - 1}`}
      data-current={`.form-${current}`}
      data-progress="63"
      data-has-statements="true">
      <div className="block">
        <div className="centered">
          <div className="large">
            <p>Awesome!</p>
          </div>
          <div className="small">
            <p>What is the website address of the website you are wanting our help on?</p>
          </div>
          <div className="input-wrap">
            <label for="website_url" className="indent_text">
              What is your website url
            </label>
            <input
              onKeyUp={checkHasRealURL}
              ref={inputEl}
              id="website_url"
              autocomplete="off"
              type="text"
              placeholder="edge45.co.uk"></input>
            <span className={isValid === true ? "desc-text inactive" : "desc-text"}>
              Please enter a valid url
            </span>
          </div>
          <div
            id="route-1_step-4_option-1"
            ref={clickRef}
            onClick={updateRoute}
            className="submit button button-capsule no_hoverBG hover">
            Next{" "}
            <span id="route-1_step-4_option-1--INNER" className="arrow">
              &rarr;
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};
