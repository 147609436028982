import React, {createRef, useEffect, useState}  from 'react'

export const FormSection6 = ({nextSection, websiteUrl, setHowCanWeHelp, currentSlideIndex}) => {
  const current = 7;
  const wrapper = createRef()
  const input = createRef()
  const clickRef = createRef()
  const [hasValue, setHasValue] = useState(false)
  const [hasValueBeenSet, setHasValueBeenSet] = useState(false)


  useEffect(() => {
    if(currentSlideIndex === 6){
      setHasValueBeenSet(false)
    }
  },[currentSlideIndex])

  const checkHasText = (e) => {
    if (e.key === 'Enter' && e.ctrlKey && hasValue === true) {
      updateRoute()
      setHasValueBeenSet(false)
      input.current.blur()
      //console.log('section 6 complete')
    }
    if(e.target.value){
      if(hasValueBeenSet === false){
        setHasValue(true)
        setHasValueBeenSet(true)
      }
      wrapper.current.querySelector('.submit').classList.add('active');
    }else{
      setHasValue(false)
      setHasValueBeenSet(false)
      wrapper.current.querySelector('.submit').classList.remove('active');
    }
  }

  const updateRoute  = (event) => {
    const formfield = wrapper.current.dataset.formfield;
    const answer = input.current.value;
    const progress = wrapper.current.dataset.progress;
    setHowCanWeHelp(answer)
    nextSection(answer, formfield, progress);
  }
  return (
    <section 
    ref={wrapper} 
    className="form-section form-6 inactive" 
    data-formfield={'form-6'}  
    data-slide={`.form-${current-1}`} 
    data-next={`.form-${current+1}`}  
    data-prev={`.form-${current-1}`}  
    data-current={`.form-${current}`}   
    data-progress={"75"} 
    data-has-statements={"true"}>
   		 <div className="block">
   		 	<div className="centered">
          <div className="small">
              <p>Tell us about <span className="insert_url">{websiteUrl ? websiteUrl : 'your website'}</span> and what you are hoping Edge45&reg; might be able to help with – The more information the better!</p>
          </div>
          <div className="input-wrap">
            <label for="more_info" className="indent_text">What can Edge45&reg; help you with?</label>
            <textarea onKeyUp={checkHasText} ref={input} id="more_info"></textarea>
          </div>
          <div id="route-1_step-5_option-1" ref={clickRef} onClick={updateRoute} className="submit button button-capsule no_hoverBG hover">Next <span  id="route-1_step-5_option-1--INNER" className="arrow">&rarr;</span></div>
   		 	</div>
   		 </div>
		</section>
  )
}
