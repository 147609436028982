import React, { Component, createRef } from "react";
import { FormSection1 } from "../components/contact/FormSection1";
import { FormSection2 } from "../components/contact/FormSection2";
import { FormSection3 } from "../components/contact/FormSection3";
import { FormSection4 } from "../components/contact/FormSection4";
import { FormSection5 } from "../components/contact/FormSection5";
import { FormSection6 } from "../components/contact/FormSection6";
import { FormSection7 } from "../components/contact/FormSection7";
import { FormSection8 } from "../components/contact/FormSection8";
import { FormSection9 } from "../components/contact/FormSection9";
import { FormSection10 } from "../components/contact/FormSection10";
import { FormSection11 } from "../components/contact/FormSection11";
import { MasterForm } from "../components/contact/MasterForm";
import { ShapesButtonProgress } from "../components/contact/ShapesButtonProgress";
import { SchemaLinksToAbsolute } from "../helpers/SchemaLinksToAbsolute";

import { Route2FormSection2 } from "../components/contact/Route2/Route2Section2";
import { Route2FormSection3 } from "../components/contact/Route2/Route2Section3";
import { Route2FormSection4 } from "../components/contact/Route2/Route2Section4";
import { Route2FormSection5 } from "../components/contact/Route2/Route2Section5";

import styled from "styled-components";
import { graphql } from "gatsby";
import Seo from "../components/seo";

import "../sass/pages/_contact.scss";

// import Layout from "../components/layout"
// import Header from '../components/globals/Header'
// import Footer from '../components/globals/Footer'

const Pulsar = styled.div`
  width: 40vw;
  height: 40vw;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  opacity: 0.7;

  @media only screen and (max-width: 850px) {
    width: 70vw;
    height: 70vw;
  }

  .inner {
    width: 40vw;
    height: 40vw;
    border-radius: 100%;
    animation: glow 2s ease-in-out infinite alternate;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.3), 0 0 20px rgba(255, 255, 255, 0.3),
      0 0 30px #ee6b4b, 0 0 40px #ee6b4b, 0 0 50px #ee6b4b, 0 0 60px #ee6b4b, 0 0 70px #ee6b4b;

    @media only screen and (max-width: 850px) {
      width: 70vw;
      height: 70vw;
    }
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.3), 0 0 20px rgba(255, 255, 255, 0.3),
        0 0 30px #ee6b4b, 0 0 40px #ee6b4b, 0 0 50px #ee6b4b, 0 0 60px #ee6b4b, 0 0 70px #ee6b4b;
      transform: scale(1);
    }
    to {
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.3), 0 0 30px darken(#fd9c16, 5%),
        0 0 40px darken(#fd9c16, 5%), 0 0 50px darken(#fd9c16, 5%), 0 0 60px darken(#fd9c16, 5%),
        0 0 70px darken(#fd9c16, 5%), 0 0 80px darken(#fd9c16, 5%);
      transform: scale(1.05);
    }
  }
`;

class Contact extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.formwrap = React.createRef();
    this.state = {
      route: "route-1",
      col: "col-1",
      progress: "0",
      showProgress: false,
      showBackButton: false,
      currentSlideIndex: 1,
      name: null,
      websiteUrl: null,
      business: null,
      fullName: "test",
    };
  }

  componentDidMount() {
    //console.log(this.state);

    this.formwrap.current.addEventListener("keydown", function (event) {
      if (event.keyCode === 9) {
        event.preventDefault();
      }
    });

    if (typeof document !== "undefined") {
      document.querySelector("body").setAttribute("data-bg-color", "col-1");
      document.querySelector("body").setAttribute("data-class", "");
    }

    const hover = Array.from(document.querySelectorAll(".hover"));

    hover.map((item) => {
      item.addEventListener(
        "touchstart",
        (e) => {
          item.classList.add("js_hover");
        },
        { passive: true }
      );
      item.addEventListener(
        "mouseenter",
        (e) => {
          item.classList.add("js_hover");
        },
        { passive: true }
      );
      item.addEventListener(
        "mouseleave",
        (e) => {
          item.classList.remove("js_hover");
        },
        { passive: true }
      );
      item.addEventListener(
        "touchmove",
        (e) => {
          item.classList.remove("js_hover");
        },
        { passive: true }
      );
      item.addEventListener(
        "click",
        (e) => {
          item.classList.remove("js_hover");
        },
        { passive: true }
      );
    });
  }

  componentDidUpdate() {
    // console.log(this.state);
    // console.log('curr:', this.state.currentSection);
    // console.log('next:', this.state.nextSection);
    // console.log('prev:', this.state.previousSection);
  }

  // Route 2 section change logic
  changeRoute = (progress) => {
    const currentSlide = this.formwrap.current.querySelector(
      ".route-2-form-" + this.state.currentSlideIndex
    );
    const prevSlide = this.formwrap.current.querySelector(
      ".route-2-form-" + (this.state.currentSlideIndex - 1)
    );
    const nextSlide = this.formwrap.current.querySelector(
      ".route-2-form-" + (this.state.currentSlideIndex + 1)
    );

    currentSlide.classList.remove("active");
    currentSlide.classList.add("complete");
    nextSlide.classList.remove("inactive");
    nextSlide.classList.add("active");

    this.setState({
      route: "route-2",
      showBackButton: true,
      showProgress: true,
      currentProgress: progress || 13,
      currentSlideIndex: this.state.currentSlideIndex + 1,
    });
  };

  // Route 1 section change logic
  nextSection = (answer, formfield, progress, multiplefields) => {
    const form = Array.from(document.querySelectorAll(".form-wrap .form-section"));
    // const form = Array.from(this.form.current.base.children[0]);

    // Populate Form Field
    form.map((field) => {
      if (multiplefields === "true") {
        answer.map((a, index) => {
          document.querySelector("#" + formfield[index]).value = a;
        });
      } else if (field.id === formfield) {
        document.querySelector("#" + field.id).value = answer;
      }
    });

    const currentSlide = this.formwrap.current.querySelector(
      ".form-" + this.state.currentSlideIndex
    );
    const prevSlide = this.formwrap.current.querySelector(
      ".form-" + (this.state.currentSlideIndex - 1)
    );
    const nextSlide = this.formwrap.current.querySelector(
      ".form-" + (this.state.currentSlideIndex + 1)
    );

    currentSlide.classList.remove("active");
    currentSlide.classList.add("complete");
    nextSlide.classList.remove("inactive");
    nextSlide.classList.add("active");

    this.setState({
      route: "route-1",
      showBackButton: true,
      showProgress: true,
      currentProgress: progress,
      currentSlideIndex: this.state.currentSlideIndex + 1,
    });
  };

  changeColour = (col) => {
    this.setState({
      col: col,
    });
  };

  setName = (name) => {
    this.setState({
      name: name,
    });
  };
  setFullName = (fullName) => {
    this.setState({
      fullName: fullName,
    });
  };

  setWebAddress = (url) => {
    this.setState({
      websiteUrl: url,
    });
  };
  setEmail = (email) => {
    this.setState({
      email: email,
    });
  };
  setPhone = (phone) => {
    this.setState({
      phone: phone,
    });
  };
  setBusiness = (business) => {
    this.setState({
      business: business,
    });
  };
  setService = (service) => {
    this.setState({
      service: service,
    });
  };
  setHowCanWeHelp = (howCanWeHelp) => {
    this.setState({
      howCanWeHelp: howCanWeHelp,
    });
  };
  setBudget = (budget) => {
    this.setState({
      budget: budget,
    });
  };
  setPreferredContact = (preferredContact) => {
    this.setState({
      preferredContact: preferredContact,
    });
  };
  setSearchEngine = (searchEngine) => {
    this.setState({
      searchEngine: searchEngine,
    });
  };
  setSearchQuery = (searchQuery) => {
    this.setState({
      searchQuery: searchQuery,
    });
  };

  goBack = () => {
    if (this.state.route === "route-1") {
      const currentSlide = this.formwrap.current.querySelector(
        ".form-" + this.state.currentSlideIndex
      );
      const prevSlide = this.formwrap.current.querySelector(
        ".form-" + (this.state.currentSlideIndex - 1)
      );

      currentSlide.classList.remove("active");
      currentSlide.classList.add("inactive");
      prevSlide.classList.remove("complete");
      prevSlide.classList.add("active");

      if (this.state.currentSlideIndex === 2) {
        this.setState({
          showBackButton: false,
          showProgress: false,
          currentProgress: prevSlide.dataset.progress,
          currentSlideIndex: 1,
          col: "col-1",
        });
      } else {
        this.setState({
          currentSlideIndex: this.state.currentSlideIndex - 1,
          currentProgress:
            this.formwrap.current.querySelector(".form-" + (this.state.currentSlideIndex - 2))
              .dataset.progress || 13,
        });
      }
    } else {
      const currentSlide = this.formwrap.current.querySelector(
        ".route-2-form-" + this.state.currentSlideIndex
      );
      const prevSlide = this.formwrap.current.querySelector(
        ".route-2-form-" + (this.state.currentSlideIndex - 1)
      );

      currentSlide.classList.remove("active");
      currentSlide.classList.add("inactive");
      prevSlide.classList.remove("complete");
      prevSlide.classList.add("active");

      if (this.state.currentSlideIndex === 2) {
        this.setState({
          showBackButton: false,
          showProgress: false,
          currentProgress: prevSlide.dataset.progress,
          currentSlideIndex: 1,
          col: "col-1",
        });
      } else {
        this.setState({
          currentSlideIndex: this.state.currentSlideIndex - 1,
          currentProgress: this.formwrap.current.querySelector(
            ".route-2-form-" + (this.state.currentSlideIndex - 2)
          ).dataset.progress,
        });
      }
    }
  };

  render() {
    return (
      <>
        <Seo
          title={this.props.data.wpPage.seo.title}
          description={this.props.data.wpPage.seo.metaDesc}
          date={this.props.data.wpPage.date}
          slug={"/" + this.props.data.wpPage.slug + "/"}
          robotsFollow={this.props.data.wpPage.seo.metaRobotsNofollow}
          robotsIndex={this.props.data.wpPage.seo.metaRobotsNoindex}
          canonicalUrl={this.props.data.wpPage.seo.canonical}
        />

        {this.props.data.wpPage.seo.schema.raw !== undefined ? (
          <SchemaLinksToAbsolute schemaRaw={this.props.data.wpPage.seo.schema.raw} />
        ) : (
          ""
        )}
        <div id="body" className="contact page-template-contact" data-bg-color={this.state.col}>
          <ShapesButtonProgress
            col={this.state.col}
            currentProgress={this.state.currentProgress}
            showProgress={this.state.showProgress}
            showBackButton={this.state.showBackButton}
            goBack={this.goBack}
          />
          <Pulsar>
            <div className="inner"></div>
          </Pulsar>
          <div className="form-wrap" ref={this.formwrap}>
            <FormSection1
              section={1}
              nextSection={this.nextSection}
              changeRoute={this.changeRoute}
              changeColour={this.changeColour}
            />
            <FormSection2
              section={2}
              nextSection={this.nextSection}
              currentSlideIndex={this.state.currentSlideIndex}
              setName={this.setName}
              setFullName={this.setFullName}
            />
            <FormSection3
              section={3}
              nextSection={this.nextSection}
              currentSlideIndex={this.state.currentSlideIndex}
              setBusiness={this.setBusiness}
              name={this.state.name}
            />
            <FormSection4
              section={4}
              nextSection={this.nextSection}
              currentSlideIndex={this.state.currentSlideIndex}
              setService={this.setService}
              business={this.state.business}
              name={this.state.name}
            />
            <FormSection5
              section={5}
              nextSection={this.nextSection}
              currentSlideIndex={this.state.currentSlideIndex}
              setWebAddress={this.setWebAddress}
            />
            <FormSection6
              section={6}
              nextSection={this.nextSection}
              currentSlideIndex={this.state.currentSlideIndex}
              setHowCanWeHelp={this.setHowCanWeHelp}
              websiteUrl={this.state.websiteUrl}
            />
            <FormSection7
              section={7}
              nextSection={this.nextSection}
              currentSlideIndex={this.state.currentSlideIndex}
              setBudget={this.setBudget}
              name={this.state.name}
            />
            <FormSection8
              section={8}
              nextSection={this.nextSection}
              currentSlideIndex={this.state.currentSlideIndex}
              setEmail={this.setEmail}
              setPhone={this.setPhone}
            />
            <FormSection9
              section={9}
              nextSection={this.nextSection}
              currentSlideIndex={this.state.currentSlideIndex}
              setSearchQuery={this.setSearchQuery}
              searchQuery={this.state.searchQuery}
            />

            <FormSection10
              section={10}
              nextSection={this.nextSection}
              currentSlideIndex={this.state.currentSlideIndex}
              setPreferredContact={this.setPreferredContact}
            />
            <FormSection11 section={11} name={this.state.name} business={this.state.business} />

            <MasterForm
              nameProp={this.state.fullName}
              businessTypeProp={this.state.business}
              serviceorProductProp={this.state.service}
              websiteUrlProp={this.state.websiteUrl}
              howCanWeHelpProp={this.state.howCanWeHelp}
              budgetProp={this.state.budget}
              emailProp={this.state.email}
              phoneProp={this.state.phone}
              searchQueryProp={this.state.searchQuery}
              preferredContactProp={this.state.preferredContact}
              ref={this.form}
            />

            <Route2FormSection2
              section={2}
              changeRoute={this.changeRoute}
              setName={this.setName}
              currentSlideIndex={this.state.currentSlideIndex}
            />
            <Route2FormSection3
              section={3}
              changeRoute={this.changeRoute}
              currentSlideIndex={this.state.currentSlideIndex}
            />
            <Route2FormSection4
              section={4}
              changeRoute={this.changeRoute}
              currentSlideIndex={this.state.currentSlideIndex}
            />
            <Route2FormSection5
              section={5}
              changeRoute={this.changeRoute}
              currentSlideIndex={this.state.currentSlideIndex}
            />
          </div>
        </div>
      </>
    );
  }
}

export default Contact;

export const query = graphql`
  {
    wpPage(title: { eq: "Contact" }) {
      date
      slug
      seo {
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        canonical
        schema {
          raw
        }
      }
    }
  }
`;
