import React from 'react'
import Circle from '../../assets/contactCircle.inline.svg';
import styled from 'styled-components';

const Progress = styled.div`
  width: ${(props) => props.progress+'%'}!important;
`;

export const ShapesButtonProgress = ({col, currentProgress, showProgress, showBackButton, goBack}) => {
  return (
    <div>
      <div className="bg">
        <div className="bg-col-1"></div>
        <div className="bg-col-2"></div>
        <div className="bg-col-3"></div>
      </div>
      
      <div className="bg-shapes">
        <div id="length"></div>
        <div className="circle"></div>
        <div className="circle-2">
          <Circle />
        </div>
        <div className="circle-3">
          <Circle />
        </div>
      </div>
      
      <div onClick={goBack} className={showBackButton === false ? "button button-capsule no_hoverBG back hover" : "button button-capsule no_hoverBG back hover active"}>
        <span className="arrow">&larr;</span> <span className="text">Back</span>
      </div>
      
      <div className="progress_bar_wrap">
        <div className={showBackButton === false ? "progress_bar" : "progress_bar active"}>
          <div className="progress_percent"><span>{currentProgress}</span>%</div>
          <div className="track">
            <Progress className="progress" progress={currentProgress}></Progress>
          </div>
        </div>
      </div>
    </div>
  )
}
