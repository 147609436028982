import React, {createRef}  from 'react'
import { Slider, RangeSlider } from 'rsuite';


export const FormSection7 = ({nextSection, setBudget, name}) => {
  const current = 8;
  const wrapper = createRef()
  const slider = createRef()
  const sliderWrap = createRef()

  const updateRoute  = (event) => {
    const answer = '£'+slider.current.state.value;
    const formfield = wrapper.current.dataset.formfield;
    const progress = wrapper.current.dataset.progress;
    setBudget(answer)
    nextSection(answer, formfield, progress);
    //console.log('section 7 complete')
  }
  return (
    <section 
    ref={wrapper} 
    className="form-section form-7 inactive" 
    data-formfield={'form-7'}  
    data-slide={`.form-${current-1}`} 
    data-next={`.form-${current+1}`}  
    data-prev={`.form-${current-1}`}  
    data-current={`.form-${current}`}   
    data-progress={"88"}
    data-has-statements={"true"}>
   		 <div className="block">
   		 	<div className="centered">
		   		<div className="small">
              <p>Thanks for that information <span className="insert_name">{name}</span>. One last question now. What is your monthly budget?</p>
          </div>
		   		<div className="input-wrap" ref={sliderWrap}>
           <Slider
            ref={slider}
            defaultValue={1500}
            step={500}
            tooltip={false}
            graduated
            progress
            onChange={number => {
              //console.log('testi', number)
              if(number < 4500){
                sliderWrap.current.querySelector('.rs-slider-last-mark').previousSibling.classList.remove('active')
                sliderWrap.current.querySelector('.rs-slider-last-mark').classList.remove('active')
              }
              if(number === 4500){
                sliderWrap.current.querySelector('.rs-slider-last-mark').previousSibling.classList.add('active')
                sliderWrap.current.querySelector('.rs-slider-last-mark').classList.remove('active')
              }
              if(number === 5000){
                sliderWrap.current.querySelector('.rs-slider-last-mark').previousSibling.classList.remove('active')
                sliderWrap.current.querySelector('.rs-slider-last-mark').classList.add('active')
              }
            }}
            min={500}
            handleClassName="slider"
            max={5000}
            renderMark={mark => {
              if ([500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500].includes(mark)) {
                return <span>£{mark}</span>;
              }
              else if ([5000].includes(mark)) {
                return <span>£{mark}+</span>;
              }
              return null;
            }}
          />
		   		</div>
          
          <div id="route-1_step-6_option-1" onClick={updateRoute} className="submit button button-capsule no_hoverBG hover active">Next <span  id="route-1_step-6_option-1--INNER" className="arrow">&rarr;</span></div>
   		 	</div>
   		 </div>
		</section>
  )
}
