import React, { createRef, useEffect, useState } from "react";

export const FormSection2 = ({ nextSection, setName, setFullName, currentSlideIndex }) => {
  const inputEl = createRef();
  const wrapper = createRef();
  const clickRef = createRef();
  const [hasValue, setHasValue] = useState(false);
  const [hasValueBeenSet, setHasValueBeenSet] = useState(false);

  const current = 3;

  useEffect(() => {
    if (currentSlideIndex === 2) {
      setHasValueBeenSet(false);
    }
  }, [currentSlideIndex]);

  const checkHasText = (e) => {
    if (e.key === "Enter" && hasValue === true) {
      updateRoute();
      //console.log('section 2 complete')
    }
    if (e.target.value) {
      if (hasValueBeenSet === false) {
        setHasValue(true);
        setHasValueBeenSet(true);
      }
      wrapper.current.querySelector(".submit").classList.add("active");
    } else {
      setHasValue(false);
      wrapper.current.querySelector(".submit").classList.remove("active");
    }
  };

  const updateRoute = (event) => {
    const formfield = wrapper.current.dataset.formfield;
    const progress = wrapper.current.dataset.progress;
    const answer = inputEl.current.value;
    const name = inputEl.current.value;
    let first_name = name.substr(0, name.indexOf(" "));

    // ========================================================================================== //
    // If only entered first name, set first name to returned name (otherwise returns blank)
    // ========================================================================================== //

    if (!first_name) {
      first_name = name;
    }

    nextSection(answer, formfield, progress);
    setName(first_name);
    setHasValue(false);
  };

  const handleChange = (e) => {
    const name = inputEl.current.value;
    setFullName(name);
  };

  return (
    <section
      ref={wrapper}
      className="form-section form-2 inactive"
      data-slide={`.form-${current - 1}`}
      data-next={`.form-${current + 1}`}
      data-prev={`.form-${current - 1}`}
      data-current={`.form-${current}`}
      data-formfield={"form-2"}
      data-progress={"25"}
      data-has-statements={"false"}>
      <div className="block">
        <div className="centered">
          <div className="large">
            <p>Hello!</p>
          </div>
          <div className="small">
            <p>My name’s Edge, I’m the brains of the outfit around here, what’s your name?</p>
          </div>
          <div className="input-wrap">
            <label htmlFor="user_name" className="indent_text">
              What is your name
            </label>
            <input
              ref={inputEl}
              onChange={(e) => handleChange(e)}
              onKeyUp={checkHasText}
              id="user_name"
              autocomplete="off"
              type="text"
              placeholder="John Smith"></input>
          </div>
          <div
            id="route-1_step-1_option-1"
            ref={clickRef}
            onClick={updateRoute}
            className="submit button button-capsule no_hoverBG hover">
            Next{" "}
            <span id="route-1_step-1_option-1--INNER" className="arrow">
              &rarr;
            </span>
          </div>
          <div className="desc-text">
            This is just for our records, we’ll never share your personal data
          </div>
        </div>
      </div>
    </section>
  );
};
