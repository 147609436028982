import React, { createRef, useEffect, useState } from "react";

export const FormSection9 = ({ nextSection, searchQuery, setSearchQuery, currentSlideIndex }) => {
  const current = 10;
  const wrapper = createRef();
  const clickRef = createRef();
  const searchEngineRef = createRef();
  const searchQueryRef = createRef();

  useEffect(() => {
    if (searchQuery) {
      wrapper.current.querySelector(".submit").classList.add("active");
    }
  }, [searchQuery]);

  const handleSearchQuery = (e) => {
    const searchQuery = e.target.value;
    setSearchQuery(searchQuery);
  };

  const updateRoute = (event) => {
    const formfield = ["form-11"];
    const progress = wrapper.current.dataset.progress;
    const multiplefields = wrapper.current.dataset.multiplefields;
    const searchQuery = searchQueryRef.current.value;
    const answer = [searchQuery];
    nextSection(answer, formfield, progress, multiplefields);
  };
  return (
    <section
      ref={wrapper}
      className="form-section form-9 inactive"
      data-slide={`.form-${current - 1}`}
      data-next={`.form-${current + 1}`}
      data-prev={`.form-${current - 1}`}
      data-current={`.form-${current}`}
      data-multiplefields={"true"}
      data-progress={"99"}
      data-has-statements={"true"}>
      <div className="block">
        <div className="centered">
          <div className="input-wrap">
            <label for="phone" className="">
              What did you type to find us?
            </label>
            <input
              id="searchQuery"
              onKeyUp={(e) => handleSearchQuery(e)}
              ref={searchQueryRef}
              type="search"
              autocomplete="off"
              placeholder="Type the search query here"></input>
          </div>
          <div
            id="route-1_step-7_option-1"
            ref={clickRef}
            onClick={updateRoute}
            className="submit button button-capsule no_hoverBG hover">
            Next{" "}
            <span id="route-1_step-7_option-1--INNER" className="arrow">
              &rarr;
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};
