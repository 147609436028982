import React, {createRef, useEffect, useState}  from 'react'



export const Route2FormSection3 = ({changeRoute, setName, currentSlideIndex}) => {
  const wrapper = createRef()
  const input = createRef()
  const clickRef = createRef()
  const [hasValue, setHasValue] = useState(false)
  const [hasValueBeenSet, setHasValueBeenSet] = useState(false)
  const current = 3;

  useEffect(() => {
    if(currentSlideIndex === 6){
      setHasValueBeenSet(false)
    }
  },[currentSlideIndex])

  const checkHasText = (e) => {
    if (e.key === 'Enter' && e.ctrlKey && hasValue === true) {
      updateRoute()
      setHasValueBeenSet(false)
      input.current.blur()
      //console.log('section 6 complete')
    }
    if(e.target.value){
      if(hasValueBeenSet === false){
        setHasValue(true)
        setHasValueBeenSet(true)
      }
      wrapper.current.querySelector('.submit').classList.add('active');
    }else{
      setHasValue(false)
      setHasValueBeenSet(false)
      wrapper.current.querySelector('.submit').classList.remove('active');
    }
  }
  
  const updateRoute  = (event) => {
  
    const progress = wrapper.current.dataset.progress;

    const name = input.current.value
    

    changeRoute(progress);
  }
  
  return (
    <section 
      ref={wrapper} 
      className="form-section form-route2 route-2-form-3 form-3 inactive" 
      data-slide={`.form-${current-1}`} 
      data-progress={"68"}
    >
   		 <div className="block">
        <div className="centered">
          <div className="small">
            <p>
              Please can you tell us a bit more about what service it is you're offering...
            </p>
          </div>
          <div className="input-wrap">
            <label for="more_info" className="indent_text">What can Edge45 help you with?</label>
            <textarea onKeyUp={checkHasText} ref={input} id="more_info" />
          </div>
          <div  id="route-2_step-2_option-1" ref={clickRef} onClick={updateRoute} className="submit button button-capsule hover">Next <span id="route-2_step-2_option-1--INNER" className="arrow">&rarr;</span></div>
   		 	</div>
   		 </div>
		</section>
  )
}
