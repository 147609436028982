import React, {createRef, useEffect, useState}  from 'react'


export const Route2FormSection4 = ({changeRoute, currentSlideIndex}) => {
  console.log(currentSlideIndex)
  const wrapper = createRef()
  const emailref = createRef()
  const phoneref = createRef();
  const [emailRef, setEmailRef] = useState()
  const [phoneRef, setPhoneRef] = useState()
  const [emailFocus, setEmailFocus] = useState(false)
  const [phoneFocus, setPhoneFocus] = useState(false)
  const [isValid, setIsValid] = useState(false)
  let email = false;
  let phone = false;
  const clickRef = createRef()
  const [hasValue, setHasValue] = useState(false)
  const [hasValueBeenSet, setHasValueBeenSet] = useState(false)

  
  useEffect(() => {
    setEmailRef(emailref.current)
  },[emailref])
  useEffect(() => {
    setPhoneRef(phoneref.current)
  },[phoneref])

  const addTabListener = (e) => {
    if(e.key === 'Tab') {
      phoneRef.focus()
    }
  }


  useEffect(() => {
    if(currentSlideIndex === 3){
      setHasValueBeenSet(false)
      if(typeof window !== 'undefined'){
        document.addEventListener("keydown", addTabListener, false);
      }
    }else{
      document.removeEventListener("keydown", addTabListener, false);
    }
  },[currentSlideIndex])

  const checkIsEmail = (e) => {
    var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    
    if(emailReg.test(e.target.value) && e.target.value !== ''){
      email = true;
      wrapper.current.classList.add('hasEmail');
      wrapper.current.querySelector('.email-validate').classList.add('hide')
      wrapper.current.querySelector('.and-validate').classList.add('hide')
    }else{
      email = false;
      wrapper.current.classList.remove('hasEmail');
      wrapper.current.querySelector('.email-validate').classList.remove('hide')
    }
    check(e);
  }
  
  const checkHasNumber = (e) => {
    if(e.target.value && e.target.value.match(/\d/g).length>=11){
      phone = true
      wrapper.current.classList.add('hasPhone');
      wrapper.current.querySelector('.phone-validate').classList.add('hide')
      wrapper.current.querySelector('.and-validate').classList.add('hide')
    }else{
      phone = false
      wrapper.current.classList.remove('hasPhone');
      wrapper.current.querySelector('.phone-validate').classList.remove('hide')
    }
    check(e);
  }

  const check = (e) =>{
    //console.log('checking...', hasValue)
    if (e.key === 'Enter' && hasValue === true) {
      updateRoute()
      //console.log('section 8 complete')
      setHasValue(false)
      setIsValid(false)
    }
    if(wrapper.current.classList.contains('hasEmail') && wrapper.current.classList.contains('hasPhone')){
      console.log('test 1')
      if(hasValueBeenSet === false){
        //console.log('test 2')
        setHasValue(true)
        setHasValueBeenSet(true)
      }
      setIsValid(true)
      wrapper.current.querySelector('.submit').classList.add('active');
    }else{
      //console.log('test 3')
      setHasValue(false)
      setIsValid(false)

      wrapper.current.querySelector('.submit').classList.remove('active');
    }
    
  }

  const updateRoute  = (event) => {
    const progress = wrapper.current.dataset.progress;
    changeRoute(progress);
  }
  return (
    <section 
    ref={wrapper} 
    className="form-section form-route2 route-2-form-4 inactive" 
    data-progress={"100"}
    data-has-statements={"true"}>
   		 <div className="block">
   		 	<div className="centered">
		   		<div className="small">
           <p>We'll need some contact details so we can get back in touch...</p>
				  </div>
          <div className="input-wrap">
            <label for="email" className="indent_text">What is your email?</label>
            <input id="email" onFocus={(e) => setEmailFocus(true)} onBlur={(e) => setEmailFocus(false)} onKeyUp={checkIsEmail} ref={emailref} type="search" autocomplete="off" placeholder="Email address"></input>
            <label for="phone" className="indent_text">What is your phone number?</label>
            <input id="phone" onFocus={(e) => setPhoneFocus(true)} onBlur={(e) => setPhoneFocus(false)} onKeyUp={checkHasNumber} ref={phoneref} type="search" autocomplete="off" placeholder="Phone number"></input>
            <span className={isValid === true ? "desc-text inactive" : 'desc-text'}>Please enter a valid <span className="email-validate">email address</span> <span className="and-validate">and</span> <span className="phone-validate">phone number</span>.</span>
          </div>						
          <div id="route-2_step-3_option-1--FORMSUBMISSION" ref={clickRef} onClick={updateRoute} className="submit button button-capsule no_hoverBG hover">Submit <span id="route-2_step-3_option-1--FORMSUBMISSION--INNER" className="arrow">&rarr;</span></div>
   		 	</div>
   		 </div>
		</section>
  )
}
