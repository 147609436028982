import React, { createRef } from "react";
import EmailIcon from "../../assets/email.inline.svg";
import PhoneIcon from "../../assets/phone.inline.svg";

export const FormSection10 = ({ nextSection, setPreferredContact }) => {
  const current = 11;
  const wrapper = createRef();
  const blob = createRef();

  const updateRoute = (event) => {
    const formfield = wrapper.current.dataset.formfield;
    const progress = wrapper.current.dataset.progress;
    const answer = wrapper.current.querySelector("label.selected input").value;
    setPreferredContact(answer);
    nextSection(answer, formfield, progress);
    //console.log('section 9 complete')
    // submitForm()
  };

  const submitForm = () => {
    const form = document.querySelector("#gform_submit_button_2").click();
  };

  const toggleSelected = (e) => {
    const target = e.target;
    const transformAmount = e.target.dataset.location;
    const allInputs = Array.from(target.parentNode.parentNode.childNodes);

    blob.current.style.transform = "translateX(" + transformAmount + ")";

    allInputs.map((input) => {
      input.classList.remove("selected");
    });

    target.parentNode.classList.add("selected");
  };

  return (
    <section
      ref={wrapper}
      className="form-section form-10 inactive"
      data-formfield={"form-10"}
      data-slide={`.form-${current - 1}`}
      data-next={`.form-${current + 1}`}
      data-prev={`.form-${current - 1}`}
      data-current={`.form-${current}`}
      data-progress={"100"}
      data-has-statements={"true"}>
      <div className="block">
        <div className="centered">
          <div className="large">
            <p>And finally...</p>
          </div>
          <div className="small">
            <p>What’s the best method for getting in touch with you?</p>
          </div>
          <div className="input-wrap">
            <div className="form" id="preffered_contact">
              <div ref={blob}></div>
              <label className="selected">
                <label for="best_email" className="indent_text">
                  The best way to reach me is Email
                </label>
                <input
                  onClick={toggleSelected}
                  id="best_email"
                  type="radio"
                  name="searchtype"
                  data-location="0"
                  value="email"
                />
                <div className="wrap">
                  <div className="image">
                    <EmailIcon />
                  </div>
                  <span>Email</span>
                </div>
              </label>
              <label>
                <label for="best_phone" className="indent_text">
                  The best way to reach me is Phone
                </label>
                <input
                  onClick={toggleSelected}
                  id="best_phone"
                  type="radio"
                  name="searchtype"
                  data-location="calc(100% - 0px)"
                  value="phone"
                />
                <div className="wrap">
                  <div className="image">
                    <PhoneIcon />
                  </div>
                  <span>Phone</span>
                </div>
              </label>
              <label>
                <label for="best_either" className="indent_text">
                  The best way to reach me is either Phone or email
                </label>
                <input
                  onClick={toggleSelected}
                  id="best_either"
                  type="radio"
                  name="searchtype"
                  data-location="calc(200% + 2px)"
                  value="either"
                />
                <div className="wrap">
                  <div className="image">
                    <EmailIcon />
                    <PhoneIcon className="margin-left" />
                  </div>
                  <span>Either</span>
                </div>
              </label>
            </div>
          </div>
          <div
            id="route-1_step-8_option-1--FORMSUBMISSION"
            onClick={updateRoute}
            className="submit button button-capsule no_hoverBG hover active">
            Submit
          </div>
        </div>
      </div>
    </section>
  );
};
