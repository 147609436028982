import React, {createRef}  from 'react'

export const FormSection4 = ({nextSection, setService, business, name}) => {
  const current = 5;
  const wrapper = createRef()

  const updateRoute  = (event) => {
    const answer = event.target.parentNode.dataset.answer;
    const formfield = wrapper.current.dataset.formfield;
    const progress = wrapper.current.dataset.progress;
    setService(answer)
    nextSection(answer, formfield, progress);
    //console.log('section 4 complete')
  }
  return (
    <section 
    ref={wrapper} 
    className="form-section form-4 inactive" 
    data-formfield={'form-4'}  
    data-slide={`.form-${current-1}`} 
    data-next={`.form-${current+1}`}  
    data-prev={`.form-${current-1}`}  
    data-current={`.form-${current}`}   
    data-progress={"50"}
    data-has-statements={"true"}
    >
   		 <div className="block">
   		 	<div className="centered">
		   		<div className="large">
			   		<p>Wonderful <span className="insert_name">{name}</span>.</p>
		   		</div>
		   		<div className="small">
			   		<p>Does your <span className="insert_business_type">{business}</span> sell a service or physical products?</p>
				</div>
        <div className="options-wrap">
          <div className="options">
            <div id="route-1_step-3_option-1" onClick={updateRoute} className="option hover" data-answer="Service"><span id="route-1_step-3_option-1--INNER" className="centered_flex">Service</span></div>
            <div id="route-1_step-3_option-2" onClick={updateRoute} className="option hover" data-answer="Product"><span id="route-1_step-3_option-2--INNER" className="centered_flex">Physical Product</span></div>
          </div>
        </div>
		   	
		   		<div className="desc-text">This help us provide the most relevant information you require</div>
   		 	</div>
   		 </div>
		</section>
  )
}
