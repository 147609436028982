import React, { useState, useEffect, useRef } from "react";

export const MasterForm = ({
  nameProp,
  businessTypeProp,
  serviceorProductProp,
  websiteUrlProp,
  howCanWeHelpProp,
  budgetProp,
  emailProp,
  phoneProp,
  preferredContactProp,
  searchEngineProp,
  searchQueryProp,
}) => {
  const form2 = useRef();
  const form3 = useRef();
  const form4 = useRef();
  const form5 = useRef();
  const form6 = useRef();
  const form7 = useRef();
  const form8 = useRef();
  const form9 = useRef();
  const form10 = useRef();
  const form11 = useRef();
  const form12 = useRef();

  const [name, setName] = useState();
  const [businessType, setBusinessType] = useState();
  const [serviceorProduct, setServiceorProduct] = useState();
  const [websiteUrl, setWebsiteUrl] = useState();
  const [howCanWeHelp, setHowCanWeHelp] = useState();
  const [budget, setBudget] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [preferredContact, setPreferredContact] = useState();
  // const [searchEngine, setSearchEngine] = useState();
  const [searchQuery, setSearchQuery] = useState();

  const submitForm = () => {
    const form = document.querySelector("#gform_submit_button_2").click();
  };

  const handleChange = (e) => {};

  useEffect(() => {
    console.log(nameProp);
    if (form2.current) {
      form2.current.value = nameProp;
    }
    setName(nameProp);
  }, [nameProp]);

  useEffect(() => {
    console.log(businessTypeProp);
    if (form3.current) {
      form3.current.value = businessTypeProp;
    }
    setBusinessType(businessTypeProp);
  }, [businessTypeProp]);

  useEffect(() => {
    console.log(serviceorProductProp);
    if (form4.current) {
      form4.current.value = serviceorProductProp;
    }
    setServiceorProduct(serviceorProductProp);
  }, [serviceorProductProp]);

  useEffect(() => {
    console.log(websiteUrlProp);
    if (form5.current) {
      form5.current.value = websiteUrlProp;
    }
    setWebsiteUrl(websiteUrlProp);
  }, [websiteUrlProp]);

  useEffect(() => {
    console.log(howCanWeHelpProp);
    if (form6.current) {
      form6.current.value = howCanWeHelpProp;
    }
    setHowCanWeHelp(howCanWeHelpProp);
  }, [howCanWeHelpProp]);

  useEffect(() => {
    console.log(budgetProp);
    if (form7.current) {
      form7.current.value = budgetProp;
    }
    setBudget(budgetProp);
  }, [budgetProp]);

  useEffect(() => {
    console.log(emailProp);
    if (form8.current) {
      form8.current.value = emailProp;
    }
    setEmail(emailProp);
  }, [emailProp]);

  useEffect(() => {
    console.log(phoneProp);
    if (form9.current) {
      form9.current.value = phoneProp;
    }
    setPhone(phoneProp);
  }, [phoneProp]);

  // useEffect(() => {
  //   console.log(searchEngineProp);
  //   if (form10.current) {
  //     form10.current.value = searchEngineProp;
  //   }
  //   setSearchEngine(searchEngineProp);
  // }, [searchEngineProp]);

  useEffect(() => {
    console.log(searchQueryProp);
    if (form11.current) {
      form11.current.value = searchQueryProp;
    }
    setSearchQuery(searchQueryProp);
  }, [searchQueryProp]);

  useEffect(() => {
    console.log(preferredContactProp);
    if (form12.current) {
      form12.current.value = preferredContactProp;
    }
    setPreferredContact(preferredContactProp);

    if (preferredContactProp !== undefined) {
      console.log("testing testing: ", preferredContactProp);
      setTimeout(submitForm, 500);
    }
  }, [preferredContactProp]);

  const formRef = useRef();

  // const encode = (data) => {
  //   const encodedFormData = new FormData();

  //   for (const key of Object.keys(data)) {
  //     encodedFormData.append(key, data[key]);
  //   }
  //   console.log(encodedFormData)
  //   return encodedFormData;

  // }

  function encode(data) {
    const encodedData = Object.keys(data)
      .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
    console.log(encodedData);
    return encodedData;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = formRef.current;

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...{
          name: name,
          businessType: businessType,
          serviceorProduct: serviceorProduct,
          websiteUrl: websiteUrl,
          howCanWeHelp: howCanWeHelp,
          budget: budget,
          email: email,
          phone: phone,
          // searchEngine: searchEngine,
          searchQuery: searchQuery,
          preferredContact: preferredContact,
        },
      }),
    })
      .then(() => {
        // typeof window !== "undefined" && window.gtag("event", "Contact Form Submitted", {
        //   'event_category': 'contact'
        // })
      })
      .catch((error) => alert(error));
  };

  return (
    <section className="masterFormInner">
      <form
        id="masterForm"
        className="form-wrapper"
        name="contactForm"
        method="POST"
        action={"/"}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        ref={formRef}
        onSubmit={(e) => handleSubmit(e)}>
        <input type="hidden" name="form-name" value="test" />
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" onChange={(e) => handleChange(e)} />
          </label>
        </p>
        {/* <input id="form-1" className="textField" type="text" name="How may I help" required placeholder="How may I help"/> */}
        <input
          id="form-2"
          ref={form2}
          className="textField"
          type="text"
          name="name"
          required
          placeholder="Name"
        />
        <input
          id="form-3"
          ref={form3}
          className="textField"
          type="text"
          name="businessType"
          required
          placeholder="Business Type"
        />
        <input
          id="form-4"
          ref={form4}
          className="textField"
          type="text"
          name="serviceorProduct"
          required
          placeholder="Service or Product"
        />
        <input
          id="form-5"
          ref={form5}
          className="textField"
          type="text"
          name="websiteUrl"
          required
          placeholder="URL"
        />
        <input
          id="form-6"
          ref={form6}
          className="textField"
          type="text"
          name="howCanWeHelp"
          required
          placeholder="What are you hoping Edge45 can help with?"
        />
        <input
          id="form-7"
          ref={form7}
          className="textField"
          type="text"
          name="budget"
          required
          placeholder="Budget"
        />
        <input
          id="form-8"
          ref={form8}
          className="textField"
          type="text"
          name="email"
          required
          placeholder="Email"
        />
        <input
          id="form-9"
          ref={form9}
          className="textField"
          type="text"
          name="phone"
          required
          placeholder="Phone"
        />
        {/* <input
          id="form-10"
          ref={form10}
          className="textField"
          type="text"
          name="searchEngine"
          required
          placeholder="searchEngine"
        /> */}
        <input
          id="form-11"
          ref={form11}
          className="textField"
          type="text"
          name="searchQuery"
          required
          placeholder="searchQuery"
        />
        <input
          id="form-12"
          ref={form12}
          className="textField"
          type="text"
          name="preferredContact"
          required
          placeholder="Best contact method"
        />

        <button id="gform_submit_button_2" type="submit">
          Submit CV
        </button>
      </form>
    </section>
  );
};
